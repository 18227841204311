import React from 'react';
import { styled, keyframes } from '@mui/system';

// Helper function to generate multiple box shadows
const generateBoxShadow = (n) => {
  let boxShadow = `${Math.random() * 2000}px ${Math.random() * 2000}px #FFF`;
  for (let i = 1; i < n; i++) {
    boxShadow += `, ${Math.random() * 2000}px ${Math.random() * 2000}px #FFF`;
  }
  return boxShadow;
};

// Keyframes for the star animation
const animStar = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-2000px);
  }
`;

// Styled components for the starry background
const Background = styled('div')({
  height: '150vh', // Extend beyond viewport for smooth animation
  width: '100vw',
  overflow: 'hidden',
  background: 'radial-gradient(ellipse at bottom, #1B2735 0%, #090A0F 100%)',
  position: 'relative',
  margin: 0,
  padding: 0,
  
});

const Stars = styled('div')(() => ({
  width: 1,
  height: 1,
  background: 'transparent',
  boxShadow: generateBoxShadow(700),
  animation: `${animStar} 50s linear infinite`,
  position: 'absolute',
  top: 0,
  left: 0,
  '&::after': {
    content: '""',
    position: 'absolute',
    top: '2000px',
    width: 1,
    height: 1,
    background: 'transparent',
    boxShadow: generateBoxShadow(700),
  },
}));

const Stars2 = styled(Stars)(() => ({
  width: 2,
  height: 2,
  boxShadow: generateBoxShadow(200),
  animation: `${animStar} 100s linear infinite`,
  '&::after': {
    top: '2000px',
    width: 2,
    height: 2,
    boxShadow: generateBoxShadow(200),
  },
}));

const Stars3 = styled(Stars)(() => ({
  width: 3,
  height: 3,
  boxShadow: generateBoxShadow(100),
  animation: `${animStar} 150s linear infinite`,
  '&::after': {
    top: '2000px',
    width: 3,
    height: 3,
    boxShadow: generateBoxShadow(100),
  },
}));


  const BackgroundWrapper = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '140%',
    overflow: 'hidden',
    zIndex: -1, // Keep the animation behind the content
  }));

// AnimatedBackground component
const AnimatedBackground = () => {
  return (
    <BackgroundWrapper>
      <Background>
        <Stars />
        <Stars2 />
        <Stars3 />
      </Background>
     
    </BackgroundWrapper>
  
  );
};

export default AnimatedBackground;
