import React from 'react';
import {
  Box,
  Typography,
  Snackbar,
  Alert,
  Button,
} from '@mui/material';
import { styled, keyframes } from '@mui/system';
import AnimatedBackground from './Animation';
import qrImage from '../../assets/QRscanner/WhatsApp.jpg'; // Replace with the path to your QR code image

// Floating animation keyframes
const floatAnimation = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-25px); }
  100% { transform: translateY(0); }
`;

// Styled components
const BackgroundContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: theme.spacing(2),
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  width: '60%',
  maxWidth: 400,
  margin: '0 auto',
  padding: theme.spacing(5),
  border: '1px solid #ccc',
  borderRadius: 1,
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    width: '90%',
  },
}));

const QRImage = styled('img')({
  display: 'block',
  width: '100%',
  maxWidth: 300,
  margin: '20px auto',
  borderRadius: 8,
});

const FloatingButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(2),
  fontSize: 18,
  backgroundColor: '#25D366',
  color: '#fff',
  fontWeight: 'bold',
  borderRadius: 8,
  animation: `${floatAnimation} 2s ease-in-out infinite`,
  '&:hover': {
    backgroundColor: '#1DA851',
  },
}));

const StudentForm = () => {
  const [success, setSuccess] = React.useState(false);

  const handleClose = () => {
    setSuccess(false);
  };

  return (
    <BackgroundContainer>
      <AnimatedBackground />
      <FormContainer>
        <Typography variant="h5" align="center" gutterBottom>
          Click the button below to join our WhatsApp group
        </Typography>
        <QRImage src={qrImage} alt="QR Code" />
        <FloatingButton
          href="https://chat.whatsapp.com/Euq3l1RGL7O8X0ZfEH7NaD"
          target="_blank"
        >
          Join WhatsApp Group
        </FloatingButton>
        <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Action completed successfully!
          </Alert>
        </Snackbar>
      </FormContainer>
    </BackgroundContainer>
  );
};

export default StudentForm;
