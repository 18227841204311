// src/BlogList.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Intern from './Intern';

const Internslist = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get('http://localhost:1337/blogs');
        setBlogs(response.data);
      } catch (error) {
        console.error('Error fetching blogs:', error);
      }
    };

    fetchBlogs();
  }, []);

  return (
    <div>
      {blogs.map((blog) => (
        <Intern
          key={blog.id}
          title={blog.title}
          content={blog.content}
          author={blog.author}
          published_at={blog.published_at}
        />
      ))}
    </div>
  );
};

export default Internslist;