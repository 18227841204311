import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  Button,
  Grid,
} from '@mui/material';
import axios from 'axios';

const GetCtf = () => {
  const [students, setStudents] = useState([]); // To store student data
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch student data on component mount
  useEffect(() => {
    const fetchStudents = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          'https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/getCapturetheflag'
        );
        setStudents(response.data); // Store response data in students state
      } catch (err) {
        setError('Failed to fetch student data');
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, []);

  const handleCloseSnackbar = () => {
    setError(null);
  };

  return (
    <Box sx={{ p: 2 }}>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      ) : error ? (
        <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error">
            {error}
          </Alert>
        </Snackbar>
      ) : (
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Typography variant="h6" align="center" gutterBottom>
              Capture the Flag Student Details
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <TableContainer
              component={Paper}
              sx={{
                maxWidth: '100%',
                overflowX: 'auto', // Enable horizontal scrolling on small screens
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>S.No</TableCell>
                    <TableCell>Student ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Course Name</TableCell>
                    <TableCell>Mobile Number</TableCell>
                    <TableCell>College Name</TableCell>
                    <TableCell>Laptop</TableCell>
                    <TableCell>Linux</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {students.map((student, index) => (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{student.studentid}</TableCell>
                      <TableCell>{student.name}</TableCell>
                      <TableCell>{student.email}</TableCell>
                      <TableCell>{student.courseName}</TableCell>
                      <TableCell>{student.mobilenumber}</TableCell>
                      <TableCell>{student.collegename}</TableCell>
                      <TableCell>{student.laptop}</TableCell>
                      <TableCell>{student.linux}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12} display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
              Refresh Data
            </Button>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default GetCtf;
