import React, { useState } from 'react';
import axios from 'axios';

const CertificatePage = () => {
  const [studentId, setStudentId] = useState('');
  const [certificateData, setCertificateData] = useState(null);
  const [error, setError] = useState('');

  const fetchCertificateDetails = async () => {
    try {
      setError('');
      const response = await axios.get(`http://localhost:3002/api/student/getInterncertificateByStudentid/${studentId}`);
      setCertificateData(response.data);
    } catch (err) {
      setError('Failed to fetch certificate details. Please check the Student ID.');
      setCertificateData(null);
    }
  };

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', textAlign: 'center', padding: '20px' }}>
      <h1 style={{ color: '#4A90E2' }}>Intern Certificate Retrieval</h1>
      <div style={{ marginBottom: '20px' }}>
        <input
          type="text"
          value={studentId}
          onChange={(e) => setStudentId(e.target.value)}
          placeholder="Enter Student ID"
          style={{
            padding: '10px',
            width: '300px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            fontSize: '16px',
          }}
        />
        <button
          onClick={fetchCertificateDetails}
          style={{
            padding: '10px 20px',
            marginLeft: '10px',
            backgroundColor: '#4A90E2',
            color: '#fff',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '16px',
          }}
        >
          Retrieve Certificate
        </button>
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {certificateData && (
        <div
          style={{
            width: '70%',
            margin: '0 auto',
            border: '2px solid #4A90E2',
            borderRadius: '10px',
            padding: '20px',
            backgroundColor: '#f9f9f9',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          }}
        >
          <h2 style={{ color: '#4A90E2' }}>Internship Certificate</h2>
          <p style={{ fontSize: '18px' }}>
            This is to certify that <strong>{certificateData.name}</strong> has successfully
            completed the <strong>{certificateData.courseName}</strong> internship program.
          </p>
          <p style={{ fontSize: '16px', margin: '10px 0' }}>
            <strong>Start Date:</strong> {new Date(certificateData.createdAt).toLocaleDateString()}
          </p>
          <p style={{ fontSize: '16px', margin: '10px 0' }}>
            <strong>End Date:</strong> {new Date(certificateData.updatedAt).toLocaleDateString()}
          </p>
          <p style={{ fontSize: '16px' }}>
            <strong>Contact:</strong> {certificateData.mobilenumber} | {certificateData.email}
          </p>
          <p style={{ fontStyle: 'italic', color: '#555', marginTop: '20px' }}>
            Thank you for your dedication and hard work.
          </p>
        </div>
      )}
    </div>
  );
};

export default CertificatePage;
