import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Paper,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "./ES.png"; // Background image
import logo1 from "./ethic.jpg";

const LoginPage = ({ setIsAuthenticated }) => {
  const predefinedUsername = "admin";
  const predefinedPassword = "ethic@2024";

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [selectedOption, setSelectedOption] = useState(""); // Dropdown selection state

  const navigate = useNavigate();

  const handleLogin = () => {
    if (username === predefinedUsername && password === predefinedPassword) {
      setIsAuthenticated(true);
      setErrorMessage("");

      if (selectedOption === "Invoice") {
        navigate("/invoices-table");
      } else if (selectedOption === "AddInternStudent") {
        navigate("/addinginternstudentform");
      } else {
        setErrorMessage("Please select an option from the dropdown.");
      }
    } else {
      setErrorMessage("Invalid username or password");
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#f0f2f5",
        backgroundImage: `url(${logo1})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: 5,
          maxWidth: 400,
          width: "100%",
          borderRadius: 3,
          backgroundColor: "rgba(255, 255, 255, 255)",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", marginBottom: 2 }}>
          <img
            src={logo}
            alt="Login"
            style={{ width: "165px", height: "100px", borderRadius: "0%" }}
          />
        </Box>
        <Typography
          variant="h5"
          gutterBottom
          align="center"
          sx={{ fontWeight: "bold", color: "#3f51b5" }}
        >
          Login
        </Typography>

        <TextField
          label="Username"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#3f51b5",
              },
              "&:hover fieldset": {
                borderColor: "#303f9f",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#3f51b5",
              },
            },
          }}
        />
        <TextField
          label="Password"
          type={showPassword ? "text" : "password"}
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <Button
                onClick={() => setShowPassword(!showPassword)}
                sx={{ position: "absolute", right: 10, textTransform: "none" }}
              >
                {showPassword ? "Hide" : "Show"}
              </Button>
            ),
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#3f51b5",
              },
              "&:hover fieldset": {
                borderColor: "#303f9f",
              },
              "&.Mui-focused fieldset": {
                borderColor: "#3f51b5",
              },
            },
          }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={showPassword}
              onChange={() => setShowPassword(!showPassword)}
              color="primary"
            />
          }
          label="Show Password"
          sx={{ marginTop: 1 }}
        />

        <FormControl fullWidth sx={{ marginTop: 2 }}>
          <InputLabel id="dropdown-label">Select Option</InputLabel>
          <Select
            labelId="dropdown-label"
            value={selectedOption}
            onChange={(e) => setSelectedOption(e.target.value)}
          >
            <MenuItem value="Invoice">Invoice</MenuItem>
            <MenuItem value="AddInternStudent">Add Intern Student</MenuItem>
          </Select>
        </FormControl>

        {errorMessage && (
          <Typography
            variant="body2"
            color="error"
            gutterBottom
            sx={{ marginTop: 2, textAlign: "center" }}
          >
            {errorMessage}
          </Typography>
        )}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleLogin}
          sx={{
            marginTop: 3,
            borderRadius: 100,
            padding: "12px",
            backgroundColor: "#3f51b5",
            "&:hover": {
              backgroundColor: "#303f9f",
            },
          }}
        >
          Login
        </Button>
      </Paper>
    </Box>
  );
};

export default LoginPage;
