import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link as ScrollLink } from "react-scroll"; // For smooth scrolling
import { Link as RouterLink } from "react-router-dom"; // For routing
import Sidebar from "../Nav/Sidebar";
import Backdrop from "../Elements/Backdrop";
// Assets
import Logo from "../../assets/img/white_logo.png";
import BurgerIcon from "../../assets/svg/BurgerIcon";

// Styled Components
const Wrapper = styled.nav`
  width: 100%;
  position: fixed;
  top: -5px;
  left: 0;
  z-index: 999;
  background-color: ${props => (props.scrolled ? '#262166' : 'transparent')}; // Adjust color on scroll
  height: ${props => (props.scrolled ? '60px' : '80px')};
  transition: background-color 0.5s ease, height 2s ease;
  box-shadow: ${props => (props.scrolled ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none')}; // Box shadow on scroll background-image: radial-gradient( circle farthest-corner at 3.2% 49.6%,  rgba(80,12,139,0.87) 0%, rgba(161,10,144,0.72) 83.6% );
`;

const NavInner = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right:0px;
  padding: 0 2rem; // Add some padding
  cursor:pointer;
`;

const BurgerWrapper = styled.button`
  outline: none;
  border: 0;
  background-color: transparent;
  height: 100%;
  padding: 0 15px;
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const NavItemsWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    display: none;
  }
`;

const UlWrapper = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`;
const LogoImage = styled.img`
 margin-top:20px;

 width: 20%;
 margin-left:0px;

  transition: width 0.3s ease; // Add transition for smooth resizing

  @media (max-width: 1200px) {
    width: 45%;
    margin-top:5px;
  }

  @media (max-width: 992px) {
    width: 60%;
    margin-top:0px;

  }

  @media (max-width: 768px) {
    width: 25%;
    margin-top:0px;
    max-height: 50%;
  }

  @media (max-width: 576px) {
    width: 40%;
  }

  @media (max-width: 480px) {
    width: 45%;
    margin-top:17px;
  }

  @media (max-width: 360px) {
    width: 50%;
    
  }
`;

const NavItem = styled.li`
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }

  a {
    display: block;
    padding: 10px 15px;
    font-size: 15px;
    font-weight: 600;
    color: white;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #00E676;
    }

    &.active {
      color: #00E676;
    }
  }
`;

// Component
export default function TopNavbar() {
  const [scrolled, setScrolled] = useState(false);
  const [sidebarOpen, toggleSidebar] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 700)
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflowX = sidebarOpen ? "hidden" : "auto";
  }, [sidebarOpen]);

  return (
    <>
      {/* Replace Sidebar and Backdrop components with your implementation */}
      {/* <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
      {sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />} */}

<Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
{sidebarOpen && <Backdrop toggleSidebar={toggleSidebar} />}

      <Wrapper scrolled={scrolled}>
        <NavInner>
          <RouterLink to="/">
            <LogoImage src={Logo} alt="EthicSecur"  />
          </RouterLink>
          <BurgerWrapper onClick={() => toggleSidebar(!sidebarOpen)}>
            <BurgerIcon />
          </BurgerWrapper>
          <NavItemsWrapper>
            <UlWrapper>
              <NavItem>
                <ScrollLink activeClass="active" to="home" spy smooth offset={-80}>Home</ScrollLink>
              </NavItem>
              <NavItem>
                <ScrollLink activeClass="active" to="about" spy smooth offset={-80}>About</ScrollLink>
              </NavItem>
              <NavItem>
                <ScrollLink activeClass="active" to="services" spy smooth offset={-80}>Services</ScrollLink>
              </NavItem>
              <NavItem>
                <RouterLink to="/blog">Blog</RouterLink>
              </NavItem>
              <NavItem>
                <RouterLink to="/pricing">Pricing</RouterLink>
              </NavItem>
              <NavItem>
                <RouterLink to="/careers">Careers</RouterLink>
              </NavItem>
              <NavItem>
                <ScrollLink activeClass="active" to="contact" spy smooth offset={-80}>Contact</ScrollLink>
              </NavItem>
              <NavItem>
                <RouterLink to="/Interncertifi">Certificate</RouterLink>
              </NavItem>
              <NavItem>
                <RouterLink to="/login">Login</RouterLink>
              </NavItem>
              <NavItem>
                <RouterLink to="/student"></RouterLink>
              </NavItem>
              
             
            </UlWrapper>
          </NavItemsWrapper>
        </NavInner>
      </Wrapper>
    </>
  );
}
