import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Snackbar, Alert } from '@mui/material';
import { styled } from '@mui/system';
import image from '../../assets/QRscanner/ESqr.jpg';
import topImage from '../../assets/ESlogo.png';
import AnimatedBackground from './Animation';

import axios from 'axios';

// Styled components
const FormContainer = styled(Box)({
  maxWidth: 500,
  margin: '0 auto',
  padding: 20,
  border: '1px solid #ccc',
  borderRadius: 8,
  backgroundColor: '#fff',
});

const FormGroup = styled(Box)({
  marginBottom: 20,
  display: 'flex',
  flexDirection: 'column',
});

const SubmitButton = styled(Button)({
  width: '100%',
  padding: 10,
  backgroundColor: '#007bff',
  color: '#fff',
  border: 'none',
  borderRadius: 4,
  fontSize: 16,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
});

const RegisterButton = styled(Button)({
  width: '100%',
  padding: 10,
  backgroundColor: '#007bff', // Blue color for the button
  color: '#fff',
  border: 'none',
  borderRadius: 4,
  fontSize: 16,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#0056b3',
  },
  marginBottom: '15px', // Add space between the Register and Paynow buttons
});

const QRImage = styled('img')({
  display: 'block',
  maxWidth: '100%',
  height: 'auto',
  margin: '10px 0',
});

const TopImage = styled('img')({
  display: 'block',
  width: '100%',
  maxHeight: 200,
  objectFit: 'cover',
  marginBottom: 20,
  borderRadius: 8,
});

// New DTO for register


const StudentForm = () => {
  const [formData, setFormData] = useState({
    studentId: '1',
    name: '',
    courseName: '',
    email: '',
    collegename: '',
    mobilenumber: '',
    utr: '',
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRazorpaySuccess = async (paymentId) => {
    try {
      const response = await axios.post('https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/add', {
        ...formData,
        paymentId, // Include the payment ID for reference
      });
      console.log(response.data);
      setSuccess(true);
      setFormData((prevData) => ({
        ...prevData,
        studentId: (parseInt(prevData.studentId) + 1).toString(),
        name: '',
        courseName: '',
        email: '',
        collegename: '',
        mobilenumber: '',
        utr: '',
      }));
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    }
  };

  const handlePayment = async () => {
    if (!window.Razorpay) {
      alert('Razorpay SDK failed to load. Are you online?');
      return;
    }

    const options = {
      key: 'rzp_live_xAPYPcMAEMICyU',
      amount: '19900', // Amount in currency subunits
      currency: 'INR',
      name: 'ES Ethicsecur',
      description: 'Razorpay Online Payment',
      handler: async function (response) {
        // If payment is successful, save data to the database
        console.log('Payment Successful:', response.razorpay_payment_id);
        await handleRazorpaySuccess(response.razorpay_payment_id);
      },
      prefill: {
        name: formData.name || 'ES Ethicsecur',
        email: formData.email || 'test@test.com',
        contact: formData.mobilenumber || '9876543210',
      },
      notes: {
        address: 'Razorpay corporate office',
      },
      theme: {
        color: '#00b0ff',
      },
    };

    const pay = new window.Razorpay(options);
    pay.open();
  };

  const handleClose = () => {
    setSuccess(false);
    setError(null);
  };

  const handleRegister = async () => {
    try {
      const response = await axios.post('https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/addUIUXRegisteredStudent', formData);
      console.log(response.data);
      alert("Registered Successfully");
      setSuccess(true);
      setFormData((prevData) => ({
        ...prevData,
        studentId: (parseInt(prevData.studentId) + 1).toString(),
        name: '',
        courseName: '',
        email: '',
        collegename: '',
        mobilenumber: '',
        utr: '',
      }));
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Trigger Razorpay payment on form submit
    await handlePayment();
  };

  return (
    <FormContainer>
      {/* Top Image */}
      <AnimatedBackground />
      <TopImage src={topImage} alt="Top Image" />

      <Typography variant="h4" align="center" gutterBottom style={{ fontSize: '23px' }}>
        UI/UX Registration Form
      </Typography>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <TextField
            label="Name"
            variant="outlined"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="Course Name"
            variant="outlined"
            name="courseName"
            value={formData.courseName}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="College Name"
            variant="outlined"
            name="collegename"
            value={formData.collegename}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <TextField
            label="Mobile Number"
            variant="outlined"
            name="mobilenumber"
            value={formData.mobilenumber}
            onChange={handleChange}
            inputProps={{ pattern: '^[0-9]{10}$' }}
            required
            placeholder="10-digit mobile number"
          />
        </FormGroup>
        
        {/* Register Button */}
        <RegisterButton onClick={handleRegister}>For Inquiry</RegisterButton>

        <SubmitButton type="submit" variant="contained">
          Paynow
        </SubmitButton>
      </form>
      <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success">
          Form submitted and payment successful!
        </Alert>
      </Snackbar>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
      <Typography
        variant="h6"
        align="center"
        gutterBottom
        style={{ fontSize: '15px', bottom: '30px' }}
      >
        Powered by EthicSecur SofTec
      </Typography>
    </FormContainer>
  );
};

export default StudentForm;
