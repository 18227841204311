import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import AnimatedBackground from './Animation';
import topImage from '../../../assets/ESlogo.png';

// Styled components
const BackgroundContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: theme.spacing(2),
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  width: '60%',
  maxWidth: 400,
  margin: '0 auto',
  padding: theme.spacing(5),
  border: '1px solid #ccc',
  borderRadius: 1,
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    width: '90%',
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  backgroundColor: '#007bff',
  color: '#fff',
  fontSize: 16,
  '&:hover': {
    backgroundColor: '#0056b3',
  },
}));

const TopImage = styled('img')({
  display: 'block',
  width: '100%',
  maxHeight: 150,
  objectFit: 'cover',
  marginBottom: 20,
  borderRadius: 8,
});

const StudentForm = () => {
  const [formData, setFormData] = useState({
    studentId: '1',
    name: '',
    courseName: '',
    email: '',
    collegename: '',
    mobilenumber: '',
    utr: '',
    laptop: '',
    linux: '',
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/addInterncertificate', {
        ...formData,
        createdAt: new Date(),
        updatedAt: new Date(),
      });
      setSuccess(true);
      setFormData({
        studentId: (parseInt(formData.studentId) + 1).toString(),
        name: '',
        courseName: '',
        email: '',
        collegename: '',
        mobilenumber: '',
        utr: '',
        laptop: '',
        linux: '',
      });
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    }
  };

  const handleClose = () => {
    setSuccess(false);
    setError(null);
  };

  return (
    <BackgroundContainer>
      <AnimatedBackground />
      <FormContainer>
        <TopImage src={topImage} alt="Top Image" />
        <Typography variant="h6" align="center" gutterBottom>
          Add Intern Student Form
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {[
              { label: 'Name', name: 'name' },
              { label: 'Course Name', name: 'courseName' },
              { label: 'Email', name: 'email', type: 'email' },
              { label: 'College Name', name: 'collegename' },
              {
                label: 'Mobile Number',
                name: 'mobilenumber',
                type: 'text',
                inputProps: { pattern: '^[0-9]{10}$' },
                placeholder: '10-digit mobile number',
              },
            ].map((field) => (
              <Grid item xs={12} key={field.name}>
                <TextField
                  label={field.label}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  fullWidth
                  required
                  type={field.type || 'text'}
                  {...field.inputProps && { inputProps: field.inputProps }}
                  {...field.placeholder && { placeholder: field.placeholder }}
                />
              </Grid>
            ))}

        

           

            <Grid item xs={12}>
              <SubmitButton type="submit">Submit</SubmitButton>
            </Grid>
          </Grid>
        </form>
        <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Intern student added successfully!
          </Alert>
        </Snackbar>
        <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {error}
          </Alert>
        </Snackbar>
        <Typography align="center" variant="body2" marginTop={3}>
          Powered by EthicSecur SofTec
        </Typography>
      </FormContainer>
    </BackgroundContainer>
  );
};

export default StudentForm;
