import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";
import { Box, Typography, CircularProgress, Button } from "@mui/material";
import Certificatee from "../../assets/img/blogs_image/certificate.png";
import { useParams } from "react-router-dom";
import Sign from "../../assets/img/blogs_image/Sign1.png";
import QRCode from "../../assets/img/blogs_image/QRCODE.jpg";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Certificate = () => {
  const { studentid } = useParams(); // Get the certificate ID from the URL (used as studentid)
  const [student, setStudent] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchStudentDetails = async () => {
      try {
        // Use the studentid to fetch the details
        const response = await axios.get(
          `https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/getInterncertificateByStudentid/${studentid}`
        );
        // Assuming the API returns the student details matching the studentid
        setStudent(response.data);
      } catch (err) {
        setError("Failed to fetch student details.");
      } finally {
        setLoading(false);
      }
    };

    fetchStudentDetails();
  }, [studentid]); // Fetch student details based on the ID parameter

  const handleDownloadPDF = async () => {
    const certificateElement = document.getElementById("certificate-container");
    const canvas = await html2canvas(certificateElement, {
      scale: 2, // Increase scale for better quality
    });
    const imgData = canvas.toDataURL("image/png");

    const pdf = new jsPDF("landscape", "px", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = canvas.height * (pdfWidth / canvas.width);

    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("Internship_Certificate.pdf");
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  if (!student) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Typography variant="h6" color="error">
          No certificate data available.
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Alex+Brush&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          background: "linear-gradient(to right, #0f2027, #203a43, #2c5364)",
          color: "black",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 3,
          overflow: "hidden",
        }}
      >


        <Box
          id="certificate-container"
          sx={{
            maxWidth: 900,
            width: "100%",
            p: 5,
            borderRadius: 4,
            border: "2px solid rgba(255, 255, 255, 0.3)",
            backdropFilter: "blur(8px)",
            backgroundImage: `url(${Certificatee})`, // Set the background image
            backgroundSize: "cover", // This will ensure the image covers the entire area
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            color: "black", // Set text color to black
            overflow: "hidden", // Ensure background image doesn't overflow
            height: "90%",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute", // Position absolutely within the relative parent
              top: "16px", // Distance from the top of the container
              right: "66px", // Distance from the right of the container
              fontFamily: "'Poppins', sans-serif",
              fontSize: "14px",
              fontWeight: "bold",
              color: "black", // Adjust text color as needed
            }}
          >
            Certificate No: {student.studentid}
          </Box>
          <Box sx={{ mt: 10 }}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                textAlign: "center", // Center align the text
                letterSpacing: 2,
                mt: 16,
                fontFamily: "'Times New Roman', serif",
                fontSize: "34px",
                color: "black",
                marginLeft: "0", // Align container to the left
                marginRight: "auto", // Auto margin on the right to push the element left
                width: "70%", // Set the width (adjust as needed)
              }}
            >
              CERTIFICATE
            </Typography>

            <Typography
              variant="h6"
              sx={{
                fontWeight: "light",
                letterSpacing: 7,
                textAlign: "center", // Center align the text
                marginLeft: "0", // Auto margin on the left
                marginRight: "auto", // Auto margin on the right to center it
                mb: 3,
                fontFamily: "'Cardo', serif",
                fontSize: "14px",
                color: "black", // Set text color to black
                width: "70%", // Set the width (adjust as needed)
              }}
            >
              OF INTERNSHIP
            </Typography>

            <Typography
              variant="h5"
              sx={{
                textAlign: "center",
                color: "black",
                textDecoration: "underline",
                textUnderlineOffset: 5,
                textDecorationThickness: 2,
                mb: 2,
                fontFamily: "'Alex Brush', serif",
                fontWeight: 400,
                fontStyle: "normal",
                fontSize: "40px",
                width: "70%", // Set the width like 'CERTIFICATE' section
                marginLeft: "0", // Auto margin for left
                marginRight: "auto", // Auto margin for right to center the element
              }}
            >
              {student.name}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                textAlign: "center",
                mb: 3,
                width: "70%", // Set the width like 'CERTIFICATE' section
                lineHeight: 1.8,
                fontFamily: "'DejaVu Serif', serif",
                fontSize: "14px",
                color: "black",
                marginLeft: "0", // Auto margin for left
                marginRight: "auto", // Auto margin for right to center the element
              }}
            >  This is to certify that <strong> {student.name} </strong> has
              successfully completed an internship at{" "}
              <strong>
                ES&nbsp;EthicSecur&nbsp;SofTec&nbsp;Pvt&nbsp;Ltd
              </strong>{" "}
              from{" "}
              <strong>
                {new Date(student.createdAt).toLocaleDateString()}
              </strong>{" "}
              to{" "}
              <strong>
                {new Date(student.updatedAt).toLocaleDateString()}
              </strong>
              . During this period, they worked on{" "}
              <strong>
                <span style={{ whiteSpace: "nowrap" }}>
                  {student.courseName}
                </span>
              </strong>{" "}
              and demonstrated dedication, professionalism, and a strong
              ability to learn.
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {/* Authorized Signature Section */}
              <Box
                sx={{
                  marginRight: "auto", // Push the QR code to the far right
                  position: "relative", // Allow manual adjustments
                  top: "10px", // Adjust the vertical position
                  left: "8%", // Adjust the horizontal position
                }}
              >
                <Box
                  component="img"
                  src={Sign}
                  alt="Authorized Signature"
                  sx={{
                    height: 90,
                    width: 100,
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "'Poppins', sans-serif",
                    color: "black",
                  }}
                >
                  Authorized Signature
                </Typography>
              </Box>

              {/* QR Code Section */}
              <Box
                sx={{
                  marginLeft: "auto", // Push the QR code to the far right
                  position: "relative", // Allow manual adjustments
                  top: "10px", // Adjust the vertical position
                  right: "7%", // Adjust the horizontal position
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: "bold",
                    fontFamily: "'Poppins', sans-serif",
                    color: "black",
                  }}
                >
                  Verify Certificate
                </Typography>
                <Box
                  component="img"
                  src={QRCode} // Replace this with your QR code source
                  alt="QR Code"
                  sx={{
                    height: 100,
                    width: 100,
                    objectFit: "contain",
                  }}
                />
              </Box>
            </Box>


          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={handleDownloadPDF}
          sx={{ mt: 2, position: "absolute", bottom: 10 }}
        >
          Download Certificate
        </Button>
      </Box>
    </>
  );
};

export default Certificate;
