import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Snackbar,
  Alert,
  Grid,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import AnimatedBackground from './Animation';
import topImage from '../../assets/Paymentform/hive edu consult logo.png';
import { useNavigate } from 'react-router-dom';

// Styled components
const BackgroundContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: theme.spacing(2),
  backgroundSize: 'cover',
  backgroundPosition: 'center',
}));

const FormContainer = styled(Box)(({ theme }) => ({
  width: '60%',
  maxWidth: 400,
  margin: '0 auto',
  padding: theme.spacing(5),
  border: '1px solid #ccc',
  borderRadius: 1,
  backgroundColor: '#fff',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
    width: '90%',
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  backgroundColor: '#007bff',
  color: '#fff',
  fontSize: 16,
  '&:hover': {
    backgroundColor: '#0056b3',
  },
}));

const WhatsAppButton = styled(Button)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1),
  backgroundColor: '#25D366',
  color: '#fff',
  fontSize: 16,
  marginTop: theme.spacing(2),
  '&:hover': {
    backgroundColor: '#1DA851',
  },
}));

const TopImage = styled('img')({
  display: 'block',
  width: '100%',
  maxHeight: 150,
  objectFit: 'cover',
  marginBottom: 20,
  borderRadius: 8,
});

const StudentForm = () => {
  const [formData, setFormData] = useState({
    Name: '',
    Phonenumber: '',
    Schoolname: '',
    District: '',
    Neet_willing: 'Yes',
    class: '11th',
    course: '',
  });

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showWhatsAppButton, setShowWhatsAppButton] = useState(true);

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation for mandatory fields (Name and Phone Number)
    if (formData.Name.trim() === '' || formData.Phonenumber.trim() === '') {
      setError('Name and Phone Number are required.');
      return;
    }

    if (!/^[0-9]{10}$/.test(formData.Phonenumber)) {
      setError('Phone number must be exactly 10 digits.');
      return;
    }

    try {
      setLoading(true);
      await axios.post(
        'https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/addHivestudent',
        formData
      );
      setSuccess(true);
      setFormData({
        Name: '',
        Phonenumber: '',
        Schoolname: '',
        District: '',
        Neet_willing: 'Yes', // Default value
        class: '11th', // Default value
        course: '', // Default value
      });

      setShowWhatsAppButton(false); // Hide WhatsApp button
      navigate('/whatsapp'); // Navigate to Whatsapp.jsx page
    } catch (err) {
      setError(err.response?.data?.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setSuccess(false);
    setError(null);
  };

  return (
    <BackgroundContainer>
      <AnimatedBackground />
      <FormContainer>
        <TopImage src={topImage} alt="Top Image" />
        <Typography variant="h6" align="center" gutterBottom>
          Student Details Form
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            {/* Text Fields */}
            {[
              { label: 'Name', name: 'Name' },
              {
                label: 'Phone Number',
                name: 'Phonenumber',
                type: 'tel',
                inputProps: { pattern: '^[0-9]{10}$' },
                placeholder: '10-digit mobile number',
              },
              { label: 'School Name', name: 'Schoolname' },
              { label: 'District', name: 'District' },
            ].map((field) => (
              <Grid item xs={12} key={field.name}>
                <TextField
                  label={field.label}
                  name={field.name}
                  value={formData[field.name]}
                  onChange={handleChange}
                  fullWidth
                  required={field.name === 'Name' || field.name === 'Phonenumber'}
                  type={field.type || 'text'}
                  {...(field.inputProps && { inputProps: field.inputProps })}
                  {...(field.placeholder && { placeholder: field.placeholder })}
                />
              </Grid>
            ))}

            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <TextField
                  label="Course"
                  name="course"
                  value={formData.course}
                  onChange={handleChange}
                  fullWidth
                  placeholder="Enter Course (e.g., Biology, Computer Science)"
                />
              </FormControl>
            </Grid>

            {/* Radio Buttons */}
            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                  NEET Willing
                </FormLabel>
                <RadioGroup
                  row
                  name="Neet_willing"
                  value={formData.Neet_willing}
                  onChange={handleChange}
                  sx={{ justifyContent: 'flex-start', marginTop: 1 }}
                >
                  <FormControlLabel value="Yes" control={<Radio />} label="Yes" sx={{ marginRight: 2 }} />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" sx={{ textAlign: 'left', fontWeight: 'bold' }}>
                  Group
                </FormLabel>
                <RadioGroup
                  row
                  name="class"
                  value={formData.class}
                  onChange={handleChange}
                  sx={{ justifyContent: 'flex-start', marginTop: 1 }}
                >
                  <FormControlLabel value="11th" control={<Radio />} label="11th" sx={{ marginRight: 2 }} />
                  <FormControlLabel value="12th" control={<Radio />} label="12th" />
                </RadioGroup>
              </FormControl>
            </Grid>

           

            {/* Submit Button */}
            <Grid item xs={12}>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
              </SubmitButton>
            </Grid>
          </Grid>
        </form>

        {/* Snackbar Notifications */}
        <Snackbar open={success} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Form submitted successfully!
          </Alert>
        </Snackbar>
        <Snackbar open={!!error} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            {error}
          </Alert>
        </Snackbar>

        <Typography align="center" variant="body2" marginTop={3}>
          Powered by EthicSecur SofTec
        </Typography>
      </FormContainer>
    </BackgroundContainer>
  );
};

export default StudentForm;
