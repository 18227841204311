import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  Button,
} from '@mui/material';
import axios from 'axios';

const StudentForm = () => {
  const [students, setStudents] = useState([]); // To store student data
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch student data on component mount
  useEffect(() => {
    const fetchStudents = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          'https://qcyokzjqdb.execute-api.ap-south-1.amazonaws.com/prod/api/student/get'
        );
        setStudents(response.data); // Store response data in students state
      } catch (err) {
        setError('Failed to fetch student data');
      } finally {
        setLoading(false);
      }
    };

    fetchStudents();
  }, []);

  const handleCloseSnackbar = () => {
    setError(null);
  };

  return (
    <Box>
      {/* Display loading indicator while fetching data */}
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity="error">
            {error}
          </Alert>
        </Snackbar>
      ) : (
        <>
          <Typography variant="h6" align="center" gutterBottom>
            UI/UX Registered Students
          </Typography>

          {/* Table to display student details */}
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>S.No</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Course Name</TableCell>
                  <TableCell>Mobile Number</TableCell>
                  <TableCell>College Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student, index) => (
                  <TableRow key={student.studentid}>
                    <TableCell>{index + 1}</TableCell> {/* S.No starts from 1 */}
                    <TableCell>{student.name}</TableCell>
                    <TableCell>{student.email}</TableCell>
                    <TableCell>{student.courseName}</TableCell>
                    <TableCell>{student.mobilenumber}</TableCell>
                    <TableCell>{student.collegename}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}

      <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
        Refresh Data
      </Button>
    </Box>
  );
};

export default StudentForm;
